import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'

const CategorySingle = ({ data, pageContext }) => {
  if (!pageContext.posts) {
    return <div className="content" />
  }

  return (
    <div className="page">
      <div className="container">
        <Helmet
          title={`${data.site.siteMetadata.blogTitle} - categories: ${pageContext.categoryName}`}
        />
        <Header
          siteTitle={data.site.siteMetadata.blogTitle}
          siteSubTitle={data.site.siteMetadata.subtitle}
        />
        <div className="content">
          <h1>{pageContext.categoryName}</h1>
          <ul className="categories">
            {pageContext.posts.map((post, index) => {
              return (
                <li key={index} className="icon-folder">
                  <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <Footer
        copyright={data.site.siteMetadata.copyright}
        repositoryName={data.site.siteMetadata.repositoryName}
        repositoryLink={data.site.siteMetadata.repositoryLink}
      />
    </div>
  )
}

CategorySingle.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query CategorySingleQuery {
    site {
      siteMetadata {
        blogTitle
        subtitle
        copyright
        repositoryName
        repositoryLink
      }
    }
  }
`

export default CategorySingle
